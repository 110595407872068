<template>
  <div class="container2">
    <div>
      <div class="slider-container vertical-slider">
        <input
          type="range"
          min="0"
          :max="maxSliderValue"
          v-model="verticalSliderValue"
          @input="moveSlider"
          @mouseup="resetSlider"
          @touchend="resetSlider"
        />
      </div>
    </div>

    <div>
      <div class="slider-container horizontal-slider">
        <input
          type="range"
          min="0"
          :max="maxSliderValue"
          v-model="horizontalSliderValue"
          @input="moveSlider"
          @mouseup="resetSlider"
          @touchend="resetSlider"
        />
      </div>
      <div class="main-content">
        <div class="position-display">[ {{ this.posGlobalX }} x {{ this.posGlobalY }} ]</div>
        <BrickTable
          :key="drawBrickTable"
          :rows="rows"
          :displayedRows="displayedRows"
          @cell-clicked="sendNotification"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BrickTable from "./brick-table.vue";

export default {
  components: {
    BrickTable,
  },
  name: "MAinTable",
  props: {
    posGlobalX: {
      type: Number,
      required: true,
    },
    posGlobalY: {
      type: Number,
      required: true,
    },
    drawBrickTable: {
      type: Number,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      maxSliderValue: 100,
      keySlider: 100,
      verticalSliderValue: 50,
      horizontalSliderValue: 50,
      displayedRows: [],
    };
  },
  methods: {
    resetSlider() {
      this.horizontalSliderValue = 50;
      this.verticalSliderValue = 50;
      this.$emit("currentPos-change", {
        v: this.verticalSliderValue,
        h: this.horizontalSliderValue,
      });
    },
    moveSlider() {
      this.$emit("currentPos-change", {
        v: this.verticalSliderValue,
        h: this.horizontalSliderValue,
      });
    },
    buttonClicked() {
      // console.log("Button clicked");
      this.$emit("button-clicked");
    },
    sendNotification(data) {
      // console.log("emit cell-clicked");
      this.$emit("cell-clicked", data);
    },
  },
};
</script>

<style scoped>
.container2 {
  display: flex;
}

.main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.slider-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: #3498db;
  border-radius: 50%;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #3498db;
  border-radius: 50%;
  cursor: pointer;
}

.horizontal-slider input[type="range"] {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
  width: 400px;
  height: 8px;
}

.vertical-slider {
  width: 8px;
  height: 200px;
  writing-mode: vertical-lr;
  direction: rtl;
}

.position-display {
  align-self: flex-start;
  margin-bottom: 10px;
}
</style>
