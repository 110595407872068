<template>
  <div class="active-users">active users {{ this.totalClients }}</div>
</template>

<script>
export default {
  name: "InfoBar",
  props: {
    totalClients: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.active-users {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}
</style>
