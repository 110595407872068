<template>
  <div class="container">
    <div class="text-about">
      This is an interactive table that anyone can modify. Implemented on my enthusiasm and to test
      my capabilities.
      <p>Feel free to write me mr@one-million.click</p>
    </div>
    <InfoBar :totalClients="totalClients" />
  </div>
  <div class="main-table">
    <MAinTable
      :drawBrickTable="drawBrickTable"
      :rows="rows"
      :posGlobalX="posGlobalX"
      :posGlobalY="posGlobalY"
      @cell-clicked="sendNotification"
      @currentPos-change="currentPosChange"
    />
  </div>
</template>

<script>
import InfoBar from "./components/info-bar.vue";
import MAinTable from "./components/main-table.vue";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

export default {
  name: "App",
  components: {
    MAinTable,
    InfoBar,
  },
  data() {
    return {
      drawBrickTable: 0,
      isAuthenticated: false,
      currentPos: 0,
      fingerprint: "",
      websocket: null,
      tokenJWT: "",
      rows: [],
      intervalId: null,
      posGlobalX: 0,
      posGlobalY: 0,
      totalClients: 0,
    };
  },
  async mounted() {
    await this.getBrowserFingerprint();
    this.initWebSocket();
  },
  beforeUnmount() {
    if (this.websocket) {
      this.websocket.close();
    }
  },

  methods: {
    async initWebSocket() {
      console.log("WebSocket подключение...");
      this.websocket = new WebSocket(process.env.VUE_APP_WEBSOCKET);
      // this.websocket = new WebSocket("wss://one-million.click/socket");
      // this.websocket = new WebSocket("wss://ws.postman-echo.com/raw");

      this.websocket.onopen = () => {
        this.sendWebsocket({ event: "authentication", data: { fingerprint: this.fingerprint } });
      };

      this.websocket.onmessage = (event) => {
        const message = JSON.parse(event.data);
        if (message.event === "authentication") {
          this.isAuthenticated = true;
          this.sendWebsocket({ event: "refreshCells", data: { pos: this.currentPos } });
        } else if (message.event === "refreshCells") {
          this.rows = message.data;
          this.drawBrickTable++;
          this.posGlobalY = Math.floor(this.currentPos / 1_000_000);
          this.posGlobalX = this.currentPos - this.posGlobalY * 1_000_000;
        } else if (message.event === "notify") {
          this.totalClients = message.data.totalClients;
          this.drawBrickTable++;
        } else if (message.event === "cellClick") {
          const data = message.data;
          const posY = Math.floor((data.pos - this.currentPos) / 1_000_000);
          const posX = data.pos - this.currentPos - posY * 1_000_000;
          // console.log("pos:", posX, posY);
          if (posX < 50 && posY < 30) {
            this.rows[posY][posX] = data.char;
            this.drawBrickTable++;
          }
        }
      };

      this.websocket.onerror = (error) => {
        console.error("WebSocket ошибка:", error);
      };

      this.websocket.onclose = () => {
        // console.log("WebSocket соединение закрыто");
      };
    },
    currentPosChange(diff) {
      let dH = +diff.h - 50;
      let dV = -(+diff.v - 50);
      let kDiff = Math.max(Math.abs(dH), Math.abs(dV));
      if (kDiff < 10) kDiff = 0.5;
      else if (kDiff < 20) kDiff = 0.5;
      else if (kDiff < 30) kDiff = 0.51;
      else if (kDiff < 40) kDiff = 4;
      else if (kDiff < 50) kDiff = 20;
      else if (kDiff < 60) kDiff = 100;
      else if (kDiff < 70) kDiff = 7;
      else if (kDiff < 80) kDiff = 8;
      else if (kDiff < 90) kDiff = 9;
      else kDiff = 1;

      if (this.intervalId) clearInterval(this.intervalId);

      dH = Math.floor(dH * kDiff);
      dV = Math.floor(dV * kDiff);
      // console.log("dV, dH:", diff, dV, dH, kDiff);

      if (dH || dV)
        this.intervalId = setInterval(() => {
          this.currentPos += dH;
          this.currentPos += dV * 1_000_000;
          this.currentPos = Math.max(0, this.currentPos);
          this.currentPos = Math.min(2_000 * 999_999, this.currentPos);
          this.sendWebsocket({ event: "refreshCells", data: { pos: this.currentPos } });
        }, 50);
    },
    sendNotification(data) {
      const posClicked = this.currentPos + data.rowIndex * 1_000_000 + data.colIndex;
      // console.log("Отправка данных по WebSocket:", data.rowIndex, data.colIndex, posClicked);
      this.sendWebsocket({
        event: "cellClick",
        data: {
          pos: posClicked,
        },
      });
    },
    sendWebsocket(payload) {
      // console.log("Отправка данных по WebSocket:", payload);
      if (this.websocket && this.websocket.readyState === WebSocket.OPEN) {
        return this.websocket.send(JSON.stringify(payload));
      } else {
        console.error("WebSocket не открыт");
      }
    },
    async getBrowserFingerprint() {
      const fp = await FingerprintJS.load();
      const result = await fp.get();
      this.fingerprint = result.visitorId;
      // console.log("Browser Fingerprint:", result.visitorId);
    },
  },
};
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  color: #2c3e50;
  margin-top: 5px;
}

.text-about {
  top: 0;
  left: 0;
  text-align: left;
  margin: 5px;
  font-size: 14px;
  color: #34495e;
  width: 350px;
  height: 90px;
  overflow: hidden;
  background-color: #f0f0f0;
  padding: 5px;
  box-sizing: border-box;
}

.container {
  display: flex;
  margin-bottom: 20px;
}

.main-table {
  text-align: left;
  margin-left: 0;
  margin-right: auto;
}
</style>
