<template>
  <div class="table-container">
    <table>
      <tbody>
        <tr v-for="(row, rowIndex) in displayedRows" :key="rowIndex">
          <td
            v-for="(value, colIndex) in row"
            :key="colIndex"
            @click="emitCellClicked(rowIndex, colIndex)"
          >
            {{ value }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "BrickTable",
  props: {
    rows: {
      type: Array,
      required: true,
    },
  },
  data() {
    return { displayedRows: [], numberOfColumns: 0 };
  },
  mounted() {
    window.addEventListener("resize", this.updateDisplayedColumns);
    this.updateDisplayedColumns();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateDisplayedColumns);
  },
  methods: {
    emitCellClicked(rowIndex, colIndex) {
      this.$emit("cell-clicked", { rowIndex, colIndex });
    },
    updateDisplayedColumns() {
      const containerWidth = this.$el.clientWidth;
      const columnWidth = 25;
      this.numberOfColumns = Math.floor(containerWidth / columnWidth);

      const containerHeight = window.innerHeight - this.$el.getBoundingClientRect().top - 20;
      const rowHeight = 20;
      const numberOfRows = Math.floor(containerHeight / rowHeight);
      this.displayedRows = this.rows.slice(0, numberOfRows);
    },
  },
};
</script>

<style scoped>
.table-container {
  overflow-x: auto;
}
table {
  width: 100%;
  margin: 0 auto;
  border-collapse: collapse;
}
td {
  font-size: 14px;
  border: 1px solid #ddd;
  width: 17px;
  height: 17px;
  padding: 0;
  text-align: center;
  vertical-align: middle;
}
</style>
